
<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>Clientes</h1>
    </div>
    <br />
    <DataGridV3 :configuracion="dataGridConfig" :data="dataSourceGrid" :seleccionar="seleccionar" ref="gridFideicomisos"  @ready="cargarAcciones">
      <div class="row">
        <div class="col-sm-auto">
          <button class="btn btn-info" @click="cargar()"> <i class='fa fa-refresh'></i> Recargar</button>
        </div>
        <div class="col-sm-auto" v-if="$auth.can('garantias_app','personas_crear')">
          <button  class="btn btn-primary" @click="crear"><i class='fa fa-plus-circle'></i> Nuevo</button>
        </div>

        <div class="col-sm-auto" v-if="$auth.can('garantias_app','personas_crear_masivo')">
          <button  class="btn btn-primary" @click="openAltaMasiva = true" @cancelar="openAltaMasiva = false"><i class='fa fa-plus-circle'></i> Cargar clientes</button>
        </div>
      </div>

    </DataGridV3>

    <cliente v-if="openDetalle" @actualizar="cargar" @cancelar="calcelar_seleccion" :cliente_id="itemSeleccionado.id"></cliente>
    
    <formulario-cliente v-if="openModalForm" :cliente_id="itemSeleccionado.id" @cliente_guardado="cliente_generado($event)"  @cancelar="calcelar_seleccion"></formulario-cliente>

    <formulario-alta-masiva-clientes v-if="openAltaMasiva" @actualizar="cargar" @cancelar="openAltaMasiva = false"></formulario-alta-masiva-clientes>

    <Modal v-if="openModalEliminar" :options="{width: '30vw',type:'error'}" @close="openModalEliminar = false">
      <div class="title">Eliminar: {{ itemSeleccionado.nombre }} </div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás el cliente  {{ itemSeleccionado.nombre }} <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="row justify-content-end">
            <div class="col-auto"><button class="btn btn-primary" @click="eliminar"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
            <div class="col-auto"><button class="btn btn-outline-secondary" @click="calcelar_seleccion"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
          </div>
        </div>
      </div>
    </Modal>

  </div>
</template>

<script>

import Modal from "@/components/Modal.vue";
import DataGridV3 from "@/components/DataGridV3.vue";
import personasApi from "@/apps/garantias_app/api/personas/personas.api";
import FormularioCliente from "@/apps/garantias_app/pages/clientes/FormularioCliente.vue";
import Cliente from "@/apps/garantias_app/pages/clientes/Cliente.vue";
import FormularioAltaMasivaClientes from "@/apps/garantias_app/pages/clientes/FormularioAltaMasivaClientes.vue";

export default {
  name: "Clientes",
  data(){
    return {
      openModalForm: false,
      openModalEliminar: false,
      dataGridConfig: {
        name: 'garantias_clientes',
        cols: {
          acciones: '',
          external_id: 'ID externo',
          nombre_completo: 'Nombre completo',
          nombre: 'Nombre',
          apellido_paterno: 'Apellido paterno',
          apellido_materno: 'Apellido materno',
          persona_juridica: 'Persona juridica'
        }
        ,filters: {
          cols: {
            external_id: 'ID externo',
            nombre: 'Nombre',
          }
        }
        ,paginator: {
          pagina_actual: 1
          ,total_registros: 1
          ,registros_por_pagina: 20
        }
        ,mutators: {
          acciones: function(val,row,vue) {
            let acciones = "<div class='text-center'>";
            if (vue.$auth.can('garantias_app','personas_editar'))
              acciones += "<i data-action='ver_detalle' data-opcion='"+vue.$helper.toJson(row)+"' class='btn-acciones btn-sm btn-primary fa fa-edit mr-sm-2'></i>";

            return acciones+"</div>";
          },
        },
        show_search: true,
        search: ['nombre_completo'],
        selector: false,
      },
      seleccionar: false,
      dataSourceGrid: [],
      accionesCargadas: false,
      itemSeleccionado: {
        id: null,
        nombre_cliente: null,
        monto: null,
        estatus: null
      }
      ,openDetalle: false
      ,openAltaMasiva:false
    }

  },
  components: {
    FormularioAltaMasivaClientes,
    FormularioCliente,
    Cliente,
    DataGridV3,
    Modal
  },
  computed: {

  },
  mounted() {
    this.cargar()
  },
  methods: {
    cargarAcciones() {
      let btns = document.querySelectorAll('.btn-acciones');
      btns.forEach(btn => {
        btn.removeEventListener('click', this.ejecutarAccionBtn )
        btn.addEventListener('click', this.ejecutarAccionBtn)
      })
    },
    ejecutarAccionBtn(e){
      let seleccion  = this.$helper.fromJson(e.target.dataset.opcion);
      if (seleccion.id !== this.itemSeleccionado.id) {
        let accion = e.target.dataset.action;
        this.itemSeleccionado = {...seleccion}
        this[accion](seleccion);
      }
    }
    ,calcelar_seleccion(){
      this.openDetalle = false;
      this.openModalForm = false;
      this.openModalEliminar = false;

      this.itemSeleccionado = {
        id: null,
        nombre_cliente: null,
        monto: null,
        estatus: null
      };
    },
    crear(){
      this.calcelar_seleccion()
      this.openModalForm = true;
    },
    ver_detalle(){
      this.openDetalle = true;
    },
    pre_eliminar(){
      this.openModalEliminar = true;
    },
    cliente_generado(cliente){
      this.openModalForm = false
      this.cargar()

      this.itemSeleccionado = cliente
      this.ver_detalle();


    },
    async eliminar(){
      this.openModalEliminar = false;
      await personasApi.eliminar(this.itemSeleccionado.id).then(() => {
      this.$helper.showMessage('Eliminar cliente','Cliente eliminado exitosamente.', 'success', 'alert')
      this.cargar()
       this.calcelar_seleccion()
      })
    },

    async cargar() {
      let dataSource = await personasApi.index(false)
      this.dataSourceGrid = dataSource.data
    }
  }

}
</script>


<style  lang="scss">
th.dataGrid_garantias_clientes_col_nombre_completo, td.dataGrid_garantias_clientes_col_nombre_completo{
  display: none !important;
}
</style>